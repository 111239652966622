<template>
  <div class="field__label"> {{ title }} </div>
</template>

<script>
export default {
  props : {
    title : {
      type : String
    }
  }
}
</script>

<style lang="scss" src="../material.scss">

</style>